.backgroundColor {
  background-color: white;
}
.container3 {
  padding: 90px 150px 90px 150px;
  text-align: center;
}

.title {
  font-size: 33px;
  font-weight: 700;
  font-family: sans-serif;
  margin-bottom: 20px;
}

.about3 {
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 200;
  margin-bottom: 50px;
  padding: 0 350px 0 350px;
}

.aboutDirection {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.secondAboutCont {
  font-size: 30px;
  background-color: #f5f5f5;
  padding: 50px;
  border-radius: 10px;
  width: 180%;
  font-family: "Poppins", Sans-serif;
}

.SecondAbouteDesc {
  font-size: 15px;
}

.boldTitle {
  font-weight: 700;
}

.about-btn3 {
  padding: 20px;
  text-decoration: none;
  color: white;
  font-weight: 700;
  font-family: sans-serif;
  width: 20%;
  border-radius: 3px;
  background: linear-gradient(176deg, #ffffff -17%, #ef2ec8 95.75%);
}

.about-btn2 {
  padding: 20px;
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: rgb(92, 197, 253);
  font-family: sans-serif;
}

.groupedBtn {
  gap: 20px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .container3 {
    padding: 20px 20px 10px 20px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .about-btn3 {
    width: 80%;
  }
  .about {
    padding: 10px;
  }
  .about3 {
    padding: 10px;
  }
}
