.topNav-container {
  background-color: #49a3ab;
  padding: 8px;
  animation-name: fadeIn;
}
.phone-link {
  text-decoration: none;
}

.phoneNumberImg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  color: white;
  position: relative;
  top: 2px;
  font-size: 14px;
  left: 5px;
}

.emailImg {
  color: white;
  position: relative;
  top: -1px;
  font-size: 14px;
}

.phoneNumber {
  color: white;
  font-size: 15px;
  font-weight: bold;
  font-family: sans-serif;
}

.email {
  color: white;
  font-size: 15px;
  font-weight: bold;
  position: relative;
  right: 20px;
  font-family: sans-serif;
}

.iconSpacing {
  display: flex;
  gap: 20px;
}

.flexDirection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.groupedIcons {
  display: flex;
  gap: 35px;
  padding: 0 0 0 30px;
  align-items: center;
}

.fbTwitInsta {
  font-size: 25px;
  display: flex;
  gap: 5px;
  color: white !important;
  border: 0;
  position: relative;
  top: 3px;
}

.FaFacebook {
  border: 0;
}

.fbTwitInsta .facebookIcon:hover,
.fbLink a:hover {
  background: none;
  background-color: transparent;
  text-decoration: none;
}

.facebookIcon {
  color: white;
}

.twitterIcon {
  color: white;
}

.instagramIcon {
  color: white;
}

@media (max-width: 700px) {
  div .nothing {
    display: none;
  }
  .groupedIcons {
    gap: 27px;
  }
  .iconSpacing {
    gap: 14px;
  }
  .email {
    font-size: 13px;
  }
  .phoneNumber {
    font-size: 13px;
  }
  .phoneNumberImg {
    font-size: 11px;
  }
  .emailImg {
    font-size: 11px;
  }
}
