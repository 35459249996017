* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: sans-serif;
}

.getToKnowUs {
  text-align: center;
  padding: 2px;
  background: linear-gradient(178deg, #e4fdff 1%, #87ced4 95.75%);
  margin-bottom: 80px;
}

.groupedHeadings {
  text-align: center;
}

.middleH1Line {
  margin-bottom: 30px;
}

.bottomH2Line {
  color: #49a3ab;
}

.imageRow {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 15px 300px 100px 300px;
}

.wrapper {
  max-width: 100%;
  max-height: 100%;
  padding: 30px;
  margin: 20px 0 20px 0;
}

.imgage {
  width: 500px;
  position: relative;
}

.widthHeight {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  position: relative;
  right: 5%;
}

.logoBackground {
  background-image: url(https://i.postimg.cc/q7MWMjkb/ZealLogo.jpg);
  width: 166px;
  height: 298px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.imgBackground {
  // background-image: url(https://www.toletwigan.co.uk/media/1023/services.png);
  width: 100%;
  height: 100%;
  display: block;
  margin: auto;
}
.imgBackground2 {
  background-image: url(https://www.toletwigan.co.uk/media/1021/packages.png);
  width: 100%;
  display: block;
  margin: auto;
}

.content {
  width: 356px;
  height: 292px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  transition: 0.6ms;
}

.content:hover {
  opacity: 1;
}

.content div {
  font-size: 19px;
  color: #fff;
}
.pTag {
  z-index: 1;
}

.splitImgLink {
  display: flex;
  align-items: center;
}

.splitImgLink a {
  text-decoration: none;
}

.img-box .info {
  position: relative;
}

.img-box .info h1 {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, 50%);
  color: white;
  margin: 0;
  transition: 0.5s;
  text-align: center;
  font-size: 17px;
}

.img-box .info:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #515165;
  height: 100%;
  width: 100%;
  opacity: 0.7;
}

.img-box:hover .info h1 {
  top: 10%;
  opacity: 0;
  visibility: hidden;
}

.img-box .desc {
  position: absolute;
  top: 100%;
  left: 0;
  color: white;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  transition: 0.8s;
}

.img-box:hover .desc {
  top: 0;
}

.img-box .info .imgBackground {
  width: 120%;
}

.img-box .desc p {
  line-height: 1.5;
}

.img-box .desc h1 {
  margin-top: 0;
}

.img-box {
  width: 320px;
  height: 280px;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 1150px) {
  .imageRow {
    padding: 0;
    margin-bottom: 40px;
  }
  .groupedHeadings {
    padding: 0 20px 0 20px;
  }
  .bottomH2Line {
    margin-bottom: 40px;
  }
  .splitImgLink {
    display: flex;
    flex-direction: column;
  }
  .wrapper {
    padding: 0;
  }
  .logoBackground {
    margin-bottom: 15px;
    display: flex;
    position: relative;
    left: 10%;
  }
}
