.lineBreak {
  padding: 3px;
  background-color: #49a3ab;
}

.footerOurOffice {
  font-family: "Avenir", Sans-serif;
}

.contactSection {
  text-align: center;
  font-size: 14px;
}

.strongFoot {
  line-height: 1.5;
}

.footer-container {
  padding: 30px 300px 20px 300px;
  background-color: #f2f2f2;
}

.boxDropdowns {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-evenly;
}

.columnDropdown {
  display: flex;
  flex-direction: column;
}

.openingTimesTitle {
  font-size: 20px;
  margin-bottom: 20px;
}

.areasCoveredTitle {
  font-size: 20px;
  margin-bottom: 20px;
}

.contactSectionTitle {
  font-size: 20px;
  margin-bottom: 20px;
  font-family: "Avenir", Sans-serif;
}

.openingTimes p {
  margin-bottom: 20px;
}

.areasCovered p {
  margin-bottom: 20px;
}

.ZRE {
  font-family: "Avenir", Sans-serif;
}

.contactSection p {
  margin-bottom: 10px;
  font-family: "Avenir", Sans-serif;
}

.openingTimes strong {
  font-weight: 100;
}

.areasCovered strong {
  font-weight: 100;
}

.contactSection strong {
  font-weight: 100;
}

.fbTwitInsta2 {
  font-size: 25px;
  display: flex;
  gap: 15px;
  color: black;
  border: 0;
  justify-content: center;

  margin-top: 20px;
}

.facebookIcon2 {
  color: black;
}

.twitterIcon2 {
  color: black;
}

.instagramIcon2 {
  color: black;
}

.areasCovered {
  position: relative;
  right: 15px;
}

@media only screen and (max-width: 1150px) {
  .footer-container {
    padding: 50px 40px 0px 40px;
    background-color: #fcfbfb;
  }
  .boxDropdowns {
    display: flex;
    flex-direction: column;
  }
  .areasCovered {
    position: relative;
    left: 0;
  }
  .columnDropdown {
    margin-bottom: 40px;
  }
}
