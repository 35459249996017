.RICSimg {
  max-width: 30%;
  position: relative;
  margin: 0 auto;
  top: 5%;
  margin-bottom: 30px;
}

.footer-container2 {
  padding: 0px 150px 30px 150px;
}

.lineBreak2 {
  padding: 1.5px;
  background-color: #879192;
}

.bottomFooterDirection {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.officeAddress {
  display: flex;
  flex-direction: column;
}

.bottomFotterColumn {
  margin-top: 40px;
  line-height: 1.5;
  font-weight: 700;
  font-size: 13px;
}

@media only screen and (max-width: 1150px) {
  .bottomFooterDirection {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  .footer-container2 {
    padding: 10px 40px 5px 40px;
  }
  .RICSimg {
    max-width: 50%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .bottomFotterColumn {
    line-height: 2;
    font-weight: 500;
    font-family: Montserrat;
  }
}
