.backgroundColor {
  background-color: white;
}
.container3 {
  padding: 90px 150px 90px 150px;
  text-align: center;
}

.about span {
  font-weight: 900;
}

.title {
  font-size: 33px;
  font-weight: 700;
  font-family: sans-serif;
  margin-bottom: 40px;
  text-decoration: underline;
}

.about {
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 200;
  margin-bottom: 50px;
  text-align: justify;
  padding: 0 300px 0 300px;
}

.aboutDirection {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.secondAboutCont {
  font-size: 30px;
  background-color: #f5f5f5;
  padding: 50px;
  border-radius: 10px;
  width: 180%;
  font-family: "Poppins", Sans-serif;
}

.SecondAbouteDesc {
  font-size: 15px;
}

.boldTitle {
  font-weight: 700;
}

.about-btn1 {
  padding: 20px;
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #49a3ab;
  font-family: sans-serif;
}

.groupedBtn {
  gap: 20px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1400px) {
  .about-btn1 {
    width: 50%;
  }
}
