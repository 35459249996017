.contact-content-con {
  display: flex;
  padding-top: 3.5rem;
  margin: 0 300px 0 300px;
}
.contact-content-con .left-contact {
  flex: 2;
  padding: 30px 0 0 0;
}
.contact-content-con .left-contact h4 {
  margin-top: 1rem;
  font-size: 2rem;
  text-transform: uppercase;
}
.contact-content-con .left-contact p {
  margin: 1rem 0;
  line-height: 2rem;
}
.contact-content-con .left-contact .contact-info .contact-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact-content-con .left-contact .contact-info .contact-item p {
  margin: 0.3rem 0 !important;
  padding: 0 !important;
}
.contact-content-con .left-contact .contact-info .contact-item .icon {
  display: grid;
  grid-template-columns: 32px 1fr;
}
.contact-content-con .left-contact .contact-info .contact-item .icon i {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}
.contact-content-con .left-contact .contact-icon {
  display: flex;
  margin-top: 2rem;
}
.contact-content-con .left-contact .contact-icon a {
  display: flex;
  align-items: center;
  color: var(--color-white);
  background-color: var(--color-grey-5);
  cursor: pointer;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0.4rem;
  transition: all 0.4s ease-in-out;
}
.contact-content-con .left-contact .contact-icon a:hover {
  background-color: var(--color-secondary);
}
.contact-content-con .left-contact .contact-icon a:hover i {
  color: var(--color-primary);
}
.contact-content-con .left-contact .contact-icon a i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
}
.contact-content-con .right-contact {
  flex: 3;
  margin-left: 3rem;
}
.contact-content-con .right-contact .input-control {
  margin: 1.5rem 0;
}
.contact-content-con .right-contact .input-control input,
.contact-content-con .right-contact .input-control textarea {
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  padding: 0.8rem 1.1rem;
  outline: none;
  border: none;
  background-color: rgb(236, 233, 233);
  width: 100%;
  color: var(--color-white);
  resize: none;
}
.contact-content-con .right-contact .i-c-2 {
  display: flex;
}
.contact-content-con .right-contact .i-c-2 :last-child {
  margin-left: 1.5rem;
}
.contact-content-con .right-contact .submit-btn {
  display: flex;
  justify-content: flex-start;
}

.btn-con {
  display: flex;
  align-self: flex-start;
}

.main-btn {
  border-radius: 30px;
  color: inherit;
  font-weight: 600;
  position: relative;
  border: 1px solid var(--color-secondary);
  display: flex;
  align-items: center;
  overflow: hidden;
}
.main-btn .btn-text {
  padding: 0 2rem;
}
.main-btn .btn-icon {
  background-color: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 1rem;
}
.main-btn::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: all 0.4s ease-out;
  z-index: -1;
}
.main-btn:hover::before {
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary);
  transform: translateX(0);
}

.main-title {
  text-align: center;
  margin-top: 80px;
}
.main-title h2 {
  position: relative;
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: 700;
  font-style: italic;
}
.contact-container {
  margin-bottom: 80px;
}

.main-title h2 span {
  color: #49a3ab;
}

.about-container .left-about p {
  padding-left: 0;
}

.submitBtn {
  width: 100%;
  padding: 15px;
  background-color: #49a3ab;
  color: white;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: bolder;
  border: none;
}

@media screen and (max-width: 600px) {
  header {
    padding: 0 !important;
  }
  .contact-content-con {
    margin: 0;
  }
  .theme-btn {
    width: 50px;
    height: 50px;
  }
  .groupedContact {
    text-align: start;
    margin-left: 25px;
  }
  .contact-content-con .left-contact h4 {
    font-size: 20px;
  }
  .header-content .left-header .h-shape {
    width: 120%;
  }
  .header-content .left-header .h-shape .image img {
    width: 120%;
  }
  .right-header {
    padding-right: 0rem !important;
  }
  .controlls {
    top: auto;
    bottom: 0;
    flex-direction: row;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: var(--color-grey-5);
  }
  .controlls .control {
    margin: 1rem 0.3rem;
  }
  .about-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .about-container .right-about {
    grid-template-columns: repeat(1, 1fr);
    padding-top: 2.5rem;
  }
  .about-container .left-about {
    padding-right: 0;
  }
  .about-container .left-about p {
    padding-left: 0;
  }
  .timeline {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 6rem;
  }
  .section {
    padding: 2rem 2.5rem !important;
  }
  .about-stats .progress-bars {
    grid-template-columns: repeat(1, 1fr);
  }
  .portfolios {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 6rem;
    margin-top: 1rem;
  }
  .blogs {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 6rem;
  }
  .contact-content-con {
    flex-direction: column;
    margin-top: -20px;
  }
  .contact-content-con .right-contact {
    margin-left: 0;
    margin-top: 2.5rem;
  }
  .contact-content-con .right-contact .i-c-2 {
    flex-direction: column;
  }
  .contact-content-con .right-contact .i-c-2 :last-child {
    margin-left: 0;
    margin-top: 1.5rem;
  }
  .contact-content-con .right-contact {
    margin-bottom: 6rem;
  }
  .contact-item {
    margin: 1rem 0;
  }
  .contact-item p {
    font-size: 15px;
    color: var(--color-grey-2);
  }
  .contact-item span {
    font-size: 15px;
  }
  .contact-item .icon {
    grid-template-columns: 25px 1fr;
    margin-left: 25px;
    gap: 30px;
  }
  .main-title h2 {
    font-size: 2rem;
  }
  .main-title h2 span {
    font-size: 2.3rem;
  }
  .main-title h2 .bg-text {
    font-size: 2.3rem;
  }
  .contact-form {
    margin: -40px 20px 0 20px;
  }
  .contact-content-con .left-contact .contact-info .contact-item {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 30px;
  }
}
