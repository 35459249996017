.active {
  background-color: #49a3ab;
}

.heightWidth {
  max-width: 100%;
  max-height: 50%;
  display: flex;
  align-items: center;
}

.white-space {
  white-space: pre-line;
}

.logo {
  background-image: url(https://i.postimg.cc/q7MWMjkb/ZealLogo.jpg);
  width: 91px;
  height: 163px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  padding: 0px 100px 0 100px;
  position: sticky;
  z-index: 1;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background: linear-gradient(90deg, #ffffff 24%, #87ced4 95.75%);
}

nav .title {
  font-size: 1.5rem;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: black;
}

nav ul {
  display: flex;
  font-size: 13px;
  font-family: sans-serif;
  font-weight: 900;
  text-align: center;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: black;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
}

nav ul li a:not(.active):hover {
  background-color: grey;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: rgb(12, 53, 129);
  border-radius: 0.2rem;
}

@media (max-width: 800px) {
  nav .menu {
    display: flex;
  }

  nav {
    flex-direction: column;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  nav ul.open {
    display: flex;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
  }
  nav {
    background: white;
  }
}
