.hero-container {
  width: auto;
  height: auto;
  background-color: rgb(0 2 2 / 85%);
}

.hero-title {
  // text-decoration: underline;
  margin-bottom: 50px;
  line-height: 1.3;
}

.hero-subTitle {
  text-decoration: underline;
}

.hero-sub-heading {
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1.5;
  font-style: italic;
}

.hero-subTitle {
  margin-bottom: 20px;
}

.img-background {
  background-image: url(https://i.postimg.cc/YSLcBcbf/Zeal-Real-Commercial.png);
  aspect-ratio: 10/4.6;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.76;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
}

.grouped-title {
  color: white;
  font-family: sans-serif;
  font-size: 80px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 100px;
  position: relative;
  padding: 150px;
}

.heroDirection {
  display: flex;
  flex-direction: row;
  gap: 50px;
  color: white;
  justify-content: start;
  align-items: center;
  font-size: 20px;
  padding: 150px;
  margin-top: -250px;
  font-family: sans-serif;
  font-weight: 900;
}

.nextLine {
  display: flex;
  flex-direction: row;
  gap: 50px;
  color: white;
  justify-content: start;
  align-items: center;
  font-size: 20px;
  padding: 150px;
  margin-top: -270px;
  font-family: sans-serif;
  font-weight: 900;
}

.innerDirection {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.pinkTick {
  color: #e317b1;
}

.home-btn {
  background-color: #e317b1;
  color: white;
  text-decoration: none;
  padding: 30px;
  margin-left: 150px;
  position: relative;
  bottom: 88px;
  border-radius: 3px;
}

@media (max-width: 747px) {
  div .heroDirection {
    display: none;
  }

  div .innerDirection {
    display: none;
  }

  .grouped-title {
    color: white;
    font-family: sans-serif;
    font-size: 48px;
    font-weight: 900;
    text-transform: uppercase;

    position: relative;
    padding: 27px;
    text-align: center;
    line-height: 1.6;
  }
  .home-btn {
    display: flex;
    justify-content: center;
    width: 46%;
    bottom: 7%;
    padding: 20px;
    margin: 0 auto;
  }
}
